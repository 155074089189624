import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ToolPageTemplate from '../ToolPageTemplate';
import CopyToClipboardButton from '../CopyToClipboardButton';
import { useState } from 'react';

function UrlEncode() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  
  const encodeButtonClick = () => {
    try {
    setOutput(encodeURI(input));
  } catch (e) {
    setOutput("ERROR: Invalid input. Unable to encoder. Please check your input string.")
  }
  };
  const decodeButtonClick = () => {
    try {
    setOutput(decodeURI(input));
    } catch (e) {
      setOutput("ERROR: Invalid input. Unable to decode. Please check your Base64 input.")
    }
  };

  const inputChange = event => {
    setInput(event.target.value);
  };

  return (
<div>
    <ToolPageTemplate title="URL Encoder/Decoder" description="Encodes and decodes strings using URL (percent) encoding." />
    <Textarea id="input-text" placeholder="Enter a string to URL encode/decode..." minRows={5}  maxRows={10} value={input} onChange={inputChange} />
    
    <Button sx={{m:1}} variant="contained" onClick={encodeButtonClick} color="primary">URL Encode String</Button><Button sx={{m:1}} variant="contained" onClick={decodeButtonClick} color="warning">URL Decode String</Button>
    <Textarea id="output-text" placeholder="Result..." readOnly minRows={5}  maxRows={10} value={output} />
    <Stack direction="row" justifyContent="end"><CopyToClipboardButton value={output}/></Stack>
</div>
  );
}


export default UrlEncode;