import ButtonAppBar from './ButtonAppBar'
import PageTitle from './PageTitle'

function ToolPageTemplate(props) {
    let title = props.title;
    let description = props.description;
  return (
<div>
    <ButtonAppBar />
    <PageTitle 
    title={title}
    description={description}
    />
</div>
  );
}

export default ToolPageTemplate;