import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


export default function PageTitle(props) {
    let title = props.title;
    let description = props.description;
    document.title = "Ad Free Tools - " + title;
    return (
        <div>
        <Box sx={{ textAlign: 'center', m: 1 }}><Typography variant="h4" gutterBottom>
        {title}
      </Typography></Box>
        <Box sx={{ textAlign: 'center', m: 1 }}><Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography></Box>
        </div>
    );
}