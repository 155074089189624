import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ToolPageTemplate from '../ToolPageTemplate';
import CopyToClipboardButton from '../CopyToClipboardButton';
import { useState } from 'react';

function reverse(str) {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]
 
    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]
 
    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"
    
    //Step 4. Return the reversed string
    return joinArray; // "olleh"
}

function ReverseString() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  
  const encodeButtonClick = () => {
    try {
    setOutput(reverse(input));
  } catch (e) {
    setOutput("ERROR: Invalid input. Unable to encoder. Please check your input string.")
  }
  };

  const inputChange = event => {
    setInput(event.target.value);
  };

  return (
<div>
    <ToolPageTemplate title="String Reverser" description="Returns the input string, in reverse!" />
    <Textarea id="input-text" placeholder="Enter a string to reverse..." minRows={5}  maxRows={10} value={input} onChange={inputChange} />
    
    <Button sx={{m:1}} variant="contained" onClick={encodeButtonClick} color="primary">Reverse String</Button>
    <Textarea id="output-text" placeholder="Result..." readOnly minRows={5}  maxRows={10} value={output} />
    <Stack direction="row" justifyContent="end"><CopyToClipboardButton value={output}/></Stack>
</div>
  );
}


export default ReverseString;