import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import TerminalIcon from '@mui/icons-material/Terminal';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import WebIcon from '@mui/icons-material/Web';


export default function ButtonAppBar() {
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      <ListItem key="Home" disablePadding component={Link} to="/">
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
      <List>
      <ListItem key="Base64 Tools" disablePadding component={Link} to="/base64">
            <ListItemButton>
              <ListItemIcon>
                <TerminalIcon />
              </ListItemIcon>
              <ListItemText primary="Base64 Tools" />
            </ListItemButton>
          </ListItem>
      <ListItem key="Text Reverser" disablePadding component={Link} to="/reversestring">
            <ListItemButton>
              <ListItemIcon>
                <FormatTextdirectionRToLIcon />
              </ListItemIcon>
              <ListItemText primary="Text Reverser" />
            </ListItemButton>
          </ListItem>
      <ListItem key="URL Encoding Tools" disablePadding component={Link} to="/urlencode">
            <ListItemButton>
              <ListItemIcon>
                <WebIcon />
              </ListItemIcon>
              <ListItemText primary="URL Encoding Tools" />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );


  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            {list('left')}
          </Drawer>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Ad Free Tools
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}