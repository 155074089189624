import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home';

import Base64 from './tools/base64';
import UrlEncode from './tools/urlencode';
import ReverseString from './tools/reversestring';

import NoPage from './NoPage';


function App() {
  return (
<BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="base64" element={<Base64 />} />
          <Route path="urlencode" element={<UrlEncode />} />
          <Route path="reversestring" element={<ReverseString />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
