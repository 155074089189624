import ToolPageTemplate from './ToolPageTemplate';


function Home() {


  return (
<div>
    <ToolPageTemplate
    title="Welcome"
    description="No ads, ever!"
    />
</div>
  );
}


export default Home;