import { Button, Snackbar } from '@mui/material'
import { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = (props) => {
    const [open, setOpen] = useState(false)
    let value = props.value;
    const handleClick = () => {
      setOpen(true);
      navigator.clipboard.writeText(value);
    }
    
    return (
        <>
          <Button onClick={handleClick} size="small" sx={{m:0.5}}>Copy to Clipboard&nbsp;<ContentCopyIcon /></Button>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
          />
        </>
    )
}

export default CopyToClipboardButton