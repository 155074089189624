import ToolPageTemplate from './ToolPageTemplate';


function NoPage() {


  return (
<div>
    <ToolPageTemplate
    title="Error 404"
    description="No such tool found."
    />
</div>
  );
}


export default NoPage;